// React
import React from 'react';
import ReactDOM from 'react-dom';
import {
    BreakpointProvider,
    setDefaultBreakpoints,
} from 'react-socks';

// Service Worker
import * as serviceWorker from './serviceWorker';

// Components
import App from './pages/App';

// SCSS Styling
import './styles/base.scss';

const breakpoints = {
    mobile: 375,
    tablet: 768,
    desktop: 1440,
};

setDefaultBreakpoints([
    { mobile: breakpoints.mobile },
    { tablet: breakpoints.tablet },
    { desktop: breakpoints.desktop },
]);

ReactDOM.render(
    <React.StrictMode>
        <BreakpointProvider>
            <App breakpoints={breakpoints} />
        </BreakpointProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
