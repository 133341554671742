// React
import React, { Suspense } from 'react';
import {
    Switch,
    Route,
    useLocation
} from 'react-router-dom';

const ForgotPasswordRedirect = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
  
    // Determine base href based on current window location
    let baseHref;
    if (window.location.hostname === 'app.trala.com') {
        baseHref = 'https://student.trala.com/reset-password?confirmationToken=';
    } else if (window.location.hostname === 'app.staging.trala.com' || window.location.hostname === 'localhost') {
        baseHref = 'https://student.staging.trala.com/reset-password?confirmationToken=';
    }
    if(baseHref) {
        window.location.href = baseHref + token;
    }
    return null;
};

/**
 *  Routes Component
 */
const Routes = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <Route path='/deeplink/violin_lessons' component={() =>
                    window.location.href = 'https://www.trala.com/sign-up-for-violin-lessons'} />
                <Route path='/deeplink/forgotPassword' component={ForgotPasswordRedirect} />
                <Route path='/' component={() =>
                    window.location.href = 'https://www.trala.com/error/'} />
            </Switch>
        </Suspense>
    );
};

export default (Routes);
