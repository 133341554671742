// React
import React from 'react';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import  {
    useCurrentBreakpointName,
    useCurrentWidth
} from 'react-socks';

// Routed page components
import Routes from '../../routes';

// SCSS Styling
import './styles.scss';

/**
 *  App Component
 */
const App = ( props ) => {
    // Destructure given properties
    const { breakpoints } = props;

    const app = {
        breakpoints,
        layout: useCurrentBreakpointName(),
        width: useCurrentWidth()
    };

    return (
        <Router>
            <Routes app={app} />
        </Router>
    );
}

// <Routes app={app} />

export default (App);
